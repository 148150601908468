export default {
  admin: '커플러 관리자',
  menu: {
    dashboard: '대시보드',
    member: '회원 관리',
    member_profile_pending: '프로필 심사',
    member_profile_pending_new: '프로필 심사 (최초)',
    member_profile_pending_edit: '프로필 심사 (기존회원)',
    member_profile_pending_await: '심사 반려된 회원',
    member_profile_pending_favor: '1차심사 합격 회원',
    member_profile_pending_reject: '심사 거절된 회원',
    member_normal: '정상 활동',
    member_all: '전체 회원',
    member_status: '탈퇴/불량/휴면 회원',
    member_invite: '초대 순위',
    member_concierge: '큐레이터 채팅',
    member_referrer: '가입 경로 관리',
    match: '매칭 관리',
    match_list: '모든 매칭 내역',
    match_try_list: '매칭 시도',
    match_going_list: '진행 중 매칭',
    match_match: '매칭 연결',
    match_curator: '큐레이터 제안',
    match_curator_list: '큐레이터 제안 내역',
    match_review: '후기 내역',
    match_female_approve: '여성 수락',
    match_male_approve: '남성 수락',
    match_final_approve: '최종 수락',
    match_date_approve: '만남일 결정',
    match_date_today: '만남일 당일',
    match_review_step: '후기 단계',
    match_review_finish: '후기 완료',
    match_blame: '신고 내역',
    match_cancel: '캔슬 내역',
    match_chat: '3일간 채팅방 내역',
    match_location: '만남 장소',
    meeting: '그룹미팅 관리',
    meeting_list: '미팅 내역',
    meeting_chat: '채팅 내역',
    meeting_review: '후기 내역',
    meeting_blame: '신고 내역',
    meeting_penalty: '패널티 내역',
    lounge: '스퀘어 관리',
    lounge_list: '스퀘어 내역',
    lounge_comment_list: '댓글 내역',
    lounge_blame: '신고 내역',
    lounge_penalty: '페널티 내역',
    iap: '결제 관리',
    iap_log: '결제 로그',
    iap_sta: '결제 통계',
    iap_ranking: '결제 순위',
    sta: '통계 관리',
    sta_login: '로그인 통계',
    sta_signup: '가입 통계',
    sta_hourly: '시간대별 접속자',
    sta_matching: '매칭 통계',
    setting: '서비스 관리',
    setting_version: '앱 버전',
    setting_setting: '설정 목록',
    setting_term: '약관 관리',
    setting_alias: '별명 관리',
    setting_notice: '공지사항',
    setting_signup_msg: '가입인사말',
    setting_support: '고객센터',
    change_password: '비밀번호 변경',
    log_out: '로그아웃',
    partner: '파트너 설정',
  },
  modal: {
    alert: '알림',
    confirm: '확인',
    ok: '네',
    no: '아니요',
    cancel: '취소',
    close: '닫기',
    apply: '적용',
    save_confirm: '저장하시겠습니까?',
    delete_confirm: '정말 삭제하시겠습니까?',
    delete_lounge_confirm: '신고된 글을 정말 삭제하시겠습니까?',
    input_reason: '반려사유를 입력해주세요',
    input_match_desc: '추천소개문구를 입력해주세요',
    vip_confirm: '채팅을 활성화하시겠습니까?',
    vip_release_confirm: '채팅을 해제하시겠습니까?',
    block_confirm: '차단하시겠습니까?',
    unblock_confirm: '차단해제하시겠습니까?',
    hold_release_confirm: '홀딩상태를 해제하시겠습니까?',
    refund_confirm: '환불처리하시겠습니까?',
    change_status_confirm: '상태를 변경하시겠습니까?'
  },
  msg: {
    applied: '적용되였습니다',
    saved: '저장되었습니다',
    deleted: '삭제되었습니다'
  },
  common: {
    save: '저장',
    delete: '삭제',
    edit: '편집',
    add: '추가',
    search: '검색',
    nickname: '닉네임',
    gender: '성별',
    male: '남자',
    female: '여자',
    manage: '관리',
    title: '제목',
    location: '위치',
    content: '내용',
    image: '이미지',
    status: '상태',
    create_date: '생성일',
    update_date: '변경일',
    show: '현시',
    hide: '숨김',
    list: '목록',
    type: '구분',
    total: '전체',
    year: '년',
    month: '월',
    date: '일',
    unknown: 'unknown',
    accept: '승인',
    accept_cancel: '승인취소',
    accepted: '승인됨',
    reject: '반려',
    reject_cancel: '반려취소',
    rejected: '반려됨',
    normal: '정상',
    deleted: '삭제됨',
    completed: '완료됨',
    mr: '{0}님',
    key: '{0} Key',
    won: '{0} 원',
    count: '{0} 건',
    no_care: '상관 없어요'
  },
  search_option: {
    member: [
      { value: 'nickname', name: '닉네임' },
      { value: 'email', name: '아이디' },
      { value: 'id', name: 'UID' },
      { value: 'name', name: '이름' },
      { value: 'phone', name: '휴대폰번호' },
      { value: 'location', name: '거주지역' },
    ],
    meeting: [
      { value: 'nickname', name: '닉네임' },
      { value: 'email', name: '사용자아이디' },
      { value: 'id', name: 'UID' },
      { value: 'title', name: '제목' },
      { value: 'content', name: '내용' },
    ],
    comment: [
      { value: 'nickname', name: '닉네임' },
      { value: 'email', name: '사용자아이디' },
      { value: 'id', name: 'UID' },
      { value: 'content', name: '내용' },
    ],
    chat: [
      { value: 'nickname', name: '닉네임' },
      { value: 'email', name: '사용자아이디' },
      { value: 'id', name: 'UID' },
      { value: 'alias', name: '랜덤닉' },
      { value: 'content', name: '내용' },
    ],
    notice: [
      { value: 'title', name: '제목' },
      { value: 'content', name: '내용' },
    ],
    location: [
      { value: 'title', name: '장소명' },
      { value: 'category', name: '카테고리' },
      { value: 'address', name: '주소' },
    ],
    gender: [
      { value: 'M', name: '남성' },
      { value: 'F', name: '여성' },
    ],
    match: [
      { value: -1, name: '전체' },
      { value: 0, name: '만남 의향' },
      { value: 1, name: '만남 수락' },
      { value: 2, name: '최종 수락' },
      { value: 7, name: '만남 예정' },
      { value: 9, name: '만남 완료(후기 미완료)' },
      { value: 100, name: '후기 완료' },
    ],
    partner: [
      { value: 'nickname', name: '이름' },
      { value: 'user_id', name: '아이디' },
    ]
  },
  login: {
    id: '아이디',
    password: '비밀번호',
    login: '로그인',
  },
  change_password: {
    current_password: '현재 비밀번호',
    new_password: '새 비밀번호',
    confirm_password: '비밀번호 확인',
    change: '변경',
    password_not_correct: '비밀번호가 일치하지 않습니다.',
  },
  term: {
    usage: '서비스 이용약관',
    privacy: '개인정보 취급방침',
    position: '위치정보 처리',
    marketing: '마케팅정보 처리',
    sensitive: '사업자 정보',
    preview: '미리보기'
  },
  alias: {
    dessert: '디저트',
    adjective: '형용사',
    empty_name: '{0}명을 입력해주세요'
  },
  notice: {
    detail: '공지상세',
    input_require: '공지내용 또는 이미지를 입력해주세요'
  },
  signup_msg: {
    detail: '가입인사말',
    input_require: '필수정보들을 입력해주세요'
  },
  support: {
    status: '답변여부',
    answer: '답변',
    answered: '답변함',
    pending: '미답변',
    detail: '고객센터 상세',
    version_name: '버전네임',
    answer_date: '답변일',
    content: '질문내용',
    view: '상세',
    input_answer: '답변내용을 입력해주세요'
  },
  sta: {
    daily: '일별',
    monthly: '월별',
    select_start_time: '시작날짜 선택',
    select_end_time: '마감날짜 선택',
    login_total: '누적 로그인',
    login_unique: '일일 로그인',
    date: '날짜',
    sales_google: '구글마켓',
    sales_appstore: '앱스토어',
    sales_onestore: '원스토어',
    referrer_signup: '추천가입',
    hour_format: '{0}시',
    hour_avg: '평균',
    week_list: ['일', '월', '화', '수', '목', '금', '토'],
  },
  setting: {
    value: '설정값',
    creator: '작성자',
    platform: '플랫폼',
    version_name: '버전명',
    version_code: '버전코드',
    min_version: '최소버전',
    app_link: '업데이트 링크주소',
    in_review: '리뷰중'
  },
  member: {
    reinspect: '재심사',
    id: '아이디',
    birth: '출생일',
    age: '나이',
    location: '거주지',
    job: '직업/직장명',
    main_info: '기본정보',
    auth_info: '인증정보',
    extra_info: '추가정보',
    profile_info: '프로필정보',
    partner: '파트너 지정',
    familiar_info: '지인인증',
    level_info: '등급정보',
    pay_log: '결제로그',
    key_log: '키변경로그',
    change_key: '변경된 키',
    total_key: '변경후 보유키',
    change_reason: '변경사유',
    change_date: '변경일자',
    sleep_log: '휴면로그',
    sleeping: '휴면중',
    sleeping_status: '1:1 휴면',
    active: '활동중',
    now: '현재',
    invite_user: '친구초대',
    invite_count: '초대횟수',
    invite_success_count: '초대인원수',
    invite_info: '  ({0}의 {1}차)',
    invite_ratio: '성공%',
    block_phone: '지인차단',
    register_contact: '등록된 연락처',
    match: '1:1 매칭',
    match_on: '현재 진행중: {0}건',
    match_finish: '누적 완료: {0}건',
    meeting: '2:2 매칭',
    meeting_complete: '누적 완료: {0}건',
    app_info: '앱 버전',
    apply: '심사적용',
    apply2: '해당 심사 기준 적용',
    apply_confirm: '심사결과를 적용하시겠습니까?\nCoupler내부심사정책에 따라 1차심사합격 또는 수정필요여부가 결정됩니다.',
    reject: '심사 거절',
    favor_info: '선호정보',
    block_member: '지인차단',
    detail: '회원상세',
    email: '이메일',
    name: '이름',
    school: '학력',
    family: '가족관계',
    single: '결혼경험여부',
    single_list: { Y: '결혼한적 없어요', N: '돌아왔어요', '': '' },
    drink: '주량',
    religion: '종교',
    smoke: '흡연여부',
    smoke_list: { N: '비흡연', S: '금연 중', E: '전자담배', Y: '흡연 중', },
    marriage_plan: '결혼계획',
    height: '키',
    body_type: '체형',
    appeal_point: '어필포인트',
    about_me: '메인프로필 1줄 자기소개',
    appeal_extra: '큐레이터에게 전달하고 싶은말',
    intro: '상대에게 전달할 자기소개 내용',
    ritzy_comment: 'Curator\'s NOTES',
    instagram_id: '인스타그램 ID',
    youtube_id: 'Youtube ID',
    sns_id: '기타',
    video: '동영상',
    download_video: '동영상 다운로드',
    profile: '프로필',
    type: '회원유형',
    profile_public: '메인프로필 공개여부',
    mini_profile: '미니프로필',
    admin_profile: '관리자 지정프로필',
    iwant_character: '내가 원하는 상대방의 성격키워드',
    iwant_age: '소개받고 싶은 상대방 나이',
    iwant_job: '선호하는 직업',
    iwant_hate_job: '비선호하는 직업',
    iwant_height: '상대방의 키',
    iwant_drink: '상대방의 주량',
    iwant_smoke: '상대방의 흡연여부',
    iwant_single: '상대방의 돌싱 여부',
    iwant_religion: '상대방의 종교',
    iwant_single_list: { Y: '원하지 않아요', N: '상관 없어요' },
    iwant_mbti: '소개를 원하는 MBTI',
    iwant_info: '그 외 원하는 이상형 정보',
    mbti: '나의 MBTI',
    mbti_public: 'MBTI 공개여부',
    mbti_public_list: { Y: '공개', N: '비공개' },
    progress: '선호하는 만남 진도 속도',
    character: '나를 나타내는 성격키워드',
    qa_first_date: '어떤 데이트를 선호하시나요?',
    qa_second_date: '두번째 만남 데이트',
    qa_location_distance: '나만의 가치관은 무엇인가요?',
    qa_favorite_style: '이성을 볼때 중요하게 보는것은?',
    qa_interest: '요즘 관심사는 무엇인가요?',
    qa_weekend: '휴일에는 보통 어떻게 보내시나요?',
    referrer: '초대자',
    necessary_item: '필수항목',
    favor_item: '선호항목',
    iwant_marriage: '상대방의 결혼/연애목표',
    iwant_school: '상대방의 학력',
    my_school: '상세학력',
    photo_public: '사진 공개 여부',
    iwant_salary: '상대방의 경제력',
    iwant_asset: '상대방의 자산',
    ihate_auth: '선호하지 않는 인증',
    best_favor1: '최선호 항목1',
    best_favor2: '최선호 항목2',
    admin_check: '관리자 검수 여부',
    app_alarm_active: '앱알림 활성화 여부',
    key_total: '결재 Key(누적)',
    money_total: '결재금액(누적)',
    match_blame_cnt: '1:1 피신고수',
    meeting_blame_cnt: '2:2 피신고수',
    square_blame_cnt: '스퀘어 피신고수',
    invite_code: '추천코드',
    key: '보유키',
    user_free_key: '[{0}]님에게 무료키지급',
    free_key: '무료Key 지급',
    input_free_key: '지급하려는 무료키를 입력해주세요',
    input_free_key_reason: '무료키지급사유를 입력해주세요',
    key_success: '무료키가 지급되었습니다.',
    alarm_chat: '채팅알림',
    alarm_match: '1:1매칭알림',
    alarm_event: '이벤트알림',
    sleep: '휴면',
    sleep_date: '휴면시작날짜',
    pending_status: '심사상태',
    pending_status_list: { 0: '심사요청', '-1': '심사탈락', 1: '1차합격, 수정필요', 2: '1차합격, 취향정보작성전', 3: '완료', 4: '프로필수정' },
    status: '상태',
    status_list: { 1: '정상', 0: '심사중', '-1': '홀딩', '-2': '정지', '-3': '탈퇴' },
    status_date: '상태 변경날짜',
    status_reason: '상태 변경사유',
    fcm_token: 'FCM 토큰',
    version_code: '버전코드',
    version_name: '버전네임',
    signup_date: '가입날짜',
    signup_os: '가입시 OS',
    login_date: '최근 로그인 시간',
    login_ip: '최근 로그인 아이피',
    login_os: '로그인시 OS',
    os_list: ['', 'android', 'iOS'],
    login_cnt: '로그인횟수',
    memo: '관리자 메모 (내부용)',
    vip: '채팅 On/Off',
    vip_list: ['채팅 Off', '채팅 On'],
    level: '공식회원등급',
    level_list: { G: '특별회원', S: '정회원', C: '일반회원' },
    level_look: '외모등급',
    level_type: '회원분류',
    level_type_list: { A: 'Type A', B: 'Type B', C: 'Type C', D: 'Type D', E: 'Type E' },
    level_finance: '경제력등급',
    level_manner: '매너등급',
    level_pay: '결제등급',
    level_activity: '활동등급',
    good_look_user: '외모형회원',
    p_level: '과금등급',
    no_info: '정보없음',
    pending_info_left: '승인/반려되지 않은 항목들이 있습니다. 모두 처리해주세요.',
    select_admin_profile: '관리자지정프로필을 선택해주세요.',
    reject_confirm: '심사 거절처리하시겠습니까?\n심사 거절된 회원은 재도전을 통하여서만 다시 회원가입을 진행할수 있습니다.',
    reject_success: '심사 거절처리되었습니다',
    total_member: '전체회원',
    female_member: '여자회원',
    male_member: '남자회원',
    app_version: '설치버전',
    phone: '휴대폰번호',
    last_login_date: '최근 로그인 시간',
    release: '해제',
    vip_release: '채팅 Off',
    vip_setting: '채팅 On',
    inspect: '심사',
    select_reason: '사유선택',
    direct_input: '직접입력',
    normal: '정상',
    pending: '심사중',
    pending_list: {
      pending: '최초심사대기',
      edit_need: '심사서류 재요청',
      reapply: '가입심사 재요청',
      reject: '심사거절',
      favor: '1차심사합격'
    },
    profile_edit: {
      pending: '심사대기',
      edit_need: '심사반려',
      reapply: '심사재요청'
    },
    leave: '탈퇴',
    block: '차단',
    hold: '홀딩',
    unblock: '차단해제',
    db_delete: 'DB삭제',
    pause_auto_delete: '자동파기 정지',
    cancel_pause_auto_delete: '자동파기 정지해제',
    installed_version: '설치된 버전',
    last_update_time: '최신 업데이트 시간',
    display_status: '노출여부',
    recommend_display: '노출',
    recommend_pending: '요청',
    recommend_write: '작성',
    recommend_type: '추천사 유형',
    recommend_receive: '받은 추천사',
    recommend_send: '쓴 추천사',
    concierge_chat: '채팅',
    send: '전송',
    msg_placeholder: '메시지내용을 입력해주세요',
    market_agree: '마케팅 정보 수신동의',
    card_all: '제안된 카드',
    card_review: '매칭 완료',
    card_final_confirm: '현재 제안 중',
    card_on_going: '현재 매칭 진행 중',
    delete_expire: '삭제 의무일',
    expired: '{0}일 경과',
    card_total: '총 제안된 카드(누적)',
    card_refuse: '거절한 카드(누적)',
    card_complete: '매칭이 완료된 카드(누적)',
    count: '{0} 건'
  },
  match: {
    match: '매칭',
    suggest: '제안',
    writer: '작성자',
    other_user: '상대방',
    reporter: '신고자',
    counter_reporter: '피신고자',
    report_type: '신고사유',
    report_content: '신고내용',
    report_date: '신고일자',
    invalid_user: '매칭이 불가한 회원입니다',
    sleep_user: '휴면중인 회원입니다',
    direct_user: '회원님이 직진만남중이어서 신규매칭은 불가합니다',
    match_desc_placeholder: '추천소개문구를 작성해주세요',
    title: '장소명',
    link: '링크주소',
    category: '카테고리',
    telephone: '연락처',
    address: '주소',
    roadAddress: '국도주소',
    status: '노출여부',
    show: '노출',
    hide: '숨김',
    meet: '만남여부',
    look: '만남?/만나지 못한 사유',
    difficulty: '어려움은 없었나요?',
    happy: '즐거우셨나요?',
    comment: '코멘트',
    met: '만났습니다.',
    not_met: '만나지 못하였습니다.',
    good: '네, 괜찮았어요',
    sorry: '아쉬워요',
    my_fault: '본인 귀책',
    other_fault: '상대방의 일방적인 취소',
    detail: '매칭상세',
    step: '단계',
    path: '경로',
    time: '행위시간',
    user: '행위자',
    key: 'Key사용',
    content: '내용',
    refund: '환불여부',
    no_log: '내역이 없습니다.',
    male_necessity: '남성회원의\n필수항목 부합',
    male_favor1: '남성회원의\n최선호항목1 (부합)',
    male_favor2: '남성회원의\n최선호항목2 (부합)',
    female_necessity: '여성회원의\n필수항목 부합',
    female_favor1: '여성회원의\n최선호항목1 (부합)',
    female_favor2: '여성회원의\n최선호항목2 (부합)',
    active_user: '활성 회원',
    delete_confirm: '매칭내역을 삭제하시겠습니까?\n삭제후 모든 관련로그도 전부 삭제됩니다.',
    deny_confirm: '매칭 요청을 거절하시겠습니까?',
    male_check: '남성 결과',
    not_check: '미확인',
    want_see: '매칭요청',
    admin_deny: '거절',
    match_step: {
      pending: 'CMS의 카드 전달',
      female_card: '여성의 카드 접수',
      male_card: '남성의 카드 접수',
      final_confirm: '여성의 최종매칭 수락',
      send_info: '여성의 선호메세지 전달',
      schedule: '선제안자',
      counter_schedule: '후제안자',
      location: '남성의 장소 선택',
      chat_open: '채팅방 생성',
      chat_pause: '채팅방 비활성화',
      chatting: '채팅내역',
      leave: '단순변심 채팅방 나가기',
      blame: '채팅방 내 신고사유 발생(자동퇴장)',
      change_schedule: '일정 변경 요청 ({0})',
      leave_schedule: '일정변경 2회 누적으로 인한 채팅창 나가기(만남 취소 요청)',
      voice_call: '보이스콜 ({0})',
      review_male: '남성 - 후기',
      review_female: '여성 - 후기',
      reactivate: '채팅창 재활성화 신청',
      reactivate_accept: '재활성화 수락',
      reactivate_schedule: '재활성화 이후 - 일정 변경 요청({0})',
      send_contract: '연락처 전달({0})',
      accept_contract: '연락처 수락({0})',
      send_direct: '진지한 만남 신청',
      accept_direct: '진지한 만남 수락',
      chat_3_days_over: '3일간 채팅방 종료',
      new_curator: '큐레이터 추천',
      admin_deny: '관리자 거절'
    },
    match_path: {
      want_see: '만나보고 싶어요',
      pass: '패스',
      no_reply: '무응답',
      confirm: '최종매칭 수락하기',
      confirm_cancel: '취소하기',
      send_info: '선호메시지 전달',
      send_schedule: '일정 전달하기',
      choose_schedule: '일정 선택',
      counter_schedule: '{0}차역제안 제시하기',
      no_schedule: '선택가능한 일정 없음',
      location: '장소 결정'
    },
    match_necessity: {
      check_age: '나이',
      check_height: '키',
      check_drink: '주량',
      check_smoke: '흡연',
      check_single: '돌싱',
      check_religion: '종교',
    }
  },
  meeting: {
    meeting: '그룹미팅',
    writer: '주최자',
    meeting_info: '미팅정보',
    public: '공개방',
    private: '비공개방',
    photo_public: '공개여부',
    thumbnail: '썸네일',
    is_meet: '만남여부',
    problem: '문제점',
    meet_success: '성공',
    meet_fail: '실패',
    detail: '그룹미팅 상세',
    apply_list: '참여신청내역',
    chat_list: '채팅내역',
    blame_list: '글신고내역',
    money: '최소회비',
    location: '장소',
    mood: '무드',
    date: '날짜',
    time: '시간',
    pending: '참여신청',
    normal: '참여확정',
    escape: '탈퇴',
    review_detail: '상세보기',
    manner: '매너',
    blame_meeting: '미팅신고',
    blame_member_in_chat: '채팅방내 회원신고',
    blame_member: '회원신고',
    apply_date: '참여신청일자',
    accept_date: '참여확정일자',
    exit_date: '탈퇴일자',
    pay_list: '아이템 결제내역',
    pay_item: '결제아이템',
    pay_type: '결제내용',
    pay_date: '결제시간',
    mini: '미니',
    photo: '사진',
    view_profile: '{0}프로필확인',
    chat_open_date: '채팅방 생성일자: {0}',
    report_in_chat: '채팅방내 신고내역',
    canceled: '취소됨',
    in_progress: '진행중'
  },
  lounge: {
    square: '스퀘어',
    category: '카테고리',
    mini_public: '공개여부',
    public: '공개',
    private: '비공개',
    visit_cnt: '조회수',
    like_cnt: '좋아요수',
    cmt_cnt: '댓글수',
    blame_cnt: '신고수',
    blame_all_cnt: '신고누적수',
    all_cnt: '누적수',
    lounge_blame: '스퀘어신고',
    comment_blame: '댓글신고',
    user_blame: '회원신고',
    blame_lounge: '신고된 글',
    blame_reason: '신고사유',
    blame_content: '신고내용',
    blame_date: '신고일자',
    comment: '댓글내용',
    penalty_day_cnt: '패널티일수',
    penalty_date: '적용일자',
    penalty_reason: '사유',
    best: '베스트글',
    best_setting: '설정',
    best_release: '해제',
    best_confirm: '베스트글로 설정하시겠습니까?',
    best_release_confirm: '베스트글 설정을 해제하시겠습니까?',
    detail: '스퀘어 상세',
    lounge_info: '글 정보',
    comment_info: '댓글내역',
    report_info: '신고내역',
    random_nickname: '랜덤닉',
    penalty: '패널티 적용',
    penalty_placeholder: '패널티사유를 입력해주세요',
    penalty_confirm: '패널티를 적용하시겠습니까?',
    checked: '처리됨',
    check: '처리함'
  },
  iap: {
    total_log: '전체내역',
    normal_log: '정상내역',
    refund_log: '환불내역',
    free_key_log: '무료키 지급내역',
    date: '주문날짜',
    method: '결제수단',
    money: '결제금액',
    heart: '구매키',
    status: '결제상태',
    complete: '완료',
    refund: '환불',
    free_key: '지급키',
    free_key_date: '지급일자',
    free_key_reason: '지급사유',
    sta_01: '오늘 결제 금액',
    sta_02: '어제 결제 금액',
    sta_03: '이번주 결제 금액',
    sta_04: '이번달 결제 금액',
    sta_05: '오늘 환불된 금액',
    sta_06: '어제 환불된 금액',
    sta_07: '이번주 환불된 금액',
    sta_08: '이번달 환불된 금액',
    won: '원',
  },
  dashboard: {
    today: '오늘',
    yesterday: '어제',
    this_week: '이번주',
    this_month: '이번달',
    this_year: '올해',
    sales: '매출',
    signup_count: '{0} 가입승인자 / 가입신청자',
    signup_data: '{0}명 / {1}명 (합격율: {2}%)',
    signup_ratio: '가입성비',
    signup_ratio_male: '남자(활성)',
    signup_ratio_female: '여자(활성)',
    signup_ratio_data: '전체 {0}명 (활성 {1}명, 정상 {2}명, 휴면 {3}명)',
    iap_chart_title: '최근 30일 매출통계',
    iap_chart_money: '결제금액',
    iap_chart_count: '결제건수',
    login_chart_title: '최근 7일 로그인통계',
    signup_chart_title: '최근 7일 가입통계',
    just_go: '바로가기',
    active: '활성회원 여부'
  },
  reject_reason: {
    signup: [
      '각 해당 순서에 맞도록 선명하고 명확하게 나온 사진으로 수정해주세요!',
      '개인정보를 마스킹 처리 후 업로드 부탁드립니다.',
      '정확한 직장명 혹은 기업 규모로 입력 부탁드립니다.',
      '선명하고 명확한 화질로 업로드 부탁드립니다.',
      '인증에 필요한 필수 서류를 모두 업로드 부탁드립니다.'
    ]
  },
  noti: {
    match_report: '1:1 매칭신고 {0}건',
    meeting_report: '2:2 미팅신고 {0}건',
    meeting_chat_user_report: '2:2 채팅방내 신고 {0}건',
    meeting_user_report: '2:2 유저 신고 {0}건',
    lounge_report: '스퀘어신고 {0}건',
    lounge_cmt_report: '스퀘어댓글신고 {0}건',
    lounge_user_report: '스퀘어유저신고 {0}건',
    concierge_count: '큐레이터채팅 {0}건',
    pay_count: '새로운 결제 {0}건',
  },
  partner: {
    list: '파트너 목록',
    add: '파트너 추가',
    edit: '파트너 편집',
    input_all: '모든 내용을 입력해주세요',
    charge_partner: '전담 파트너',
    share_partner: '공유 파트너',
    not_change_partner: '진행중인 카드가 있어 파트너변경이 불가합니다.\n강제변경하시겠습니까?'
  }
}
