import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";
import { GENDER } from "../../../config/const";

const MatchDescPopup = ({ isOpen, gender, onSave, onClose }) => {

  const context = useContext(PageSettings);
  const [desc, setDesc] = useState('');

  const toggleModal = result => {
    onClose?.(result);
    setDesc('');
  }

  const onApply = () => {
    if (desc == '') {
      context.addNotification(strings.modal.alert, strings.modal.input_match_desc, 'warning');
      return;
    }

    onSave?.(desc);
    setDesc('');
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {gender == GENDER.FEMALE ? strings.match.match : strings.match.suggest}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xl-12">
            <textarea autoComplete='off' className='form-control'
                      placeholder={strings.match.match_desc_placeholder}
                      value={desc}
                      rows={5}
                      onChange={e => setDesc(e.target.value)}/>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onApply}>
          {gender == GENDER.FEMALE ? strings.match.match : strings.match.suggest}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default MatchDescPopup;
