import React, { useContext, useEffect, useRef } from "react";
import $ from 'jquery';

import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import strings from "../../../lang/strings";
import { withRouter } from "react-router-dom";
import { PageSettings } from "../../../config/page-settings";
import { inject } from "mobx-react";

export const PENDING_TYPE = {
  최초: 'new',
  기존회원: 'edit',
  심사반려: 'await',
  합격1차: 'favor',
  심사거절: 'reject'
};

const MemberPendingFragment = ({ type, tab, gender, history, rootStore }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const searchOptions = strings.search_option.member;
  const tabRef = useRef(tab);
  const genderRef = useRef(gender);

  useEffect(() => {
    $('#searchColumn').val(searchOptions[0].value);
    $('#searchInput').val('');

    tabRef.current = tab;
    genderRef.current = gender;

    refreshTable(true);
  }, [tab, gender]);

  const onCell = id => {
    history.push(`/member/detail/${id}`);
  }

  const onDelete = user_id => {
    if (!rootStore.isSuper) {
      return;
    }

    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/' + user_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)} />
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'email', orderable: false,
            }, {
              targets: [columnNo++], data: 'nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(
                  (type != PENDING_TYPE.기존회원 && type != PENDING_TYPE.합격1차) ? (item.pending_nickname ?? item.nickname) : item.nickname,
                  item.gender,
                  () => onCell(item.id)
                );
              }
            }, {
              targets: [columnNo++], data: 'pending_status', orderable: false,
              render: value => Number(value).pendingStatusStr(type != PENDING_TYPE.기존회원)
            }, {
              targets: [columnNo++], data: 'name', orderable: false,
            }, {
              targets: [columnNo++], data: 'gender', orderable: false,
              render: value => value.genderStr()
            }, {
              targets: [columnNo++], data: 'phone', orderable: false,
            }, {
              targets: [columnNo++], data: 'birth', orderable: false,
              render: value => value.ageFormat()
            }, {
              targets: [columnNo++], data: 'location', orderable: false,
            }, {
              targets: [columnNo++], data: 'job', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(type == PENDING_TYPE.최초 ? (item.pending_job ?? item.job) : item.job);
              }
            }, {
              targets: [columnNo++], data: 'signup_date', orderable: false,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'expire_date', orderable: false, visible: type == PENDING_TYPE.심사거절,
              render: value => value < 45 ? '-' : strings.formatString(strings.member.expired, value)
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html('');
                if (rootStore.isSuper) {
                  $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.member.db_delete}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDelete(item.id));
                }
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'member/pending/list',
              data: (req) => {
                // req.type = tabRef.current;
                req.type = type;    // new/edit/await/favor/reject
                req.gender = genderRef.current;
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.id}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.common.status}</th>
            <th>{strings.member.name}</th>
            <th>{strings.common.gender}</th>
            <th>{strings.member.phone}</th>
            <th>{strings.member.age}</th>
            <th>{strings.member.location}</th>
            <th>{strings.member.job}</th>
            <th>{strings.common.create_date}</th>
            <th style={{ display: type == PENDING_TYPE.심사거절 ? 'normal' : 'none' }}>{strings.member.delete_expire}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
}

export default withRouter(inject('rootStore')(MemberPendingFragment));
